.containers {
  display: flex;
  flex-direction: column;
}

.header {

}

.main {
  height: 100%;
  display: flex;
  overflow: hidden;

  :global(.ant-tabs-content) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    :global(.dd-table) {
      height: 100% !important;
    }
  }
}
