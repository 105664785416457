@import '~antd/dist/antd.css';

body {
  background: #FBFBFB;
  overflow: hidden;
}

#root {
  height: 100%;
}

#dd-body {
  label.ant-form-item-required {
    font-size: 14px;
    color: #6D7D8B;
  }

  form {
    input.ant-input {
      border: 0;
      padding: 12px 20px;
      background: rgba(241, 241, 241, 0.5);
      border-radius: 8px;

      &:focus {
        box-shadow: 0 0 0 1px #F1F1F1;
        box-sizing: border-box;
        background: #FFFFFF;
      }
    }

    input.ant-input-phone {
      height: auto;
      width: auto;
      border: 0 !important;
      background: rgba(241, 241, 241, 0.5) !important;
      border-radius: 8px !important;
      padding: 12px 20px 12px 48px !important;
      line-height: inherit;

      &:focus {
        box-shadow: 0 0 0 1px #F1F1F1 !important;;
        box-sizing: border-box !important;;
        background: #FFFFFF !important;;
      }
    }

    span.ant-input-password {
      border: 0;
      padding: 0;
      background: rgba(241, 241, 241, 0.5);
      border-radius: 8px;

      input.ant-input {
        background: none;
      }

      span.ant-input-suffix {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    span.ant-input-affix-wrapper-focused {
      box-shadow: 0 0 0 1px #F1F1F1;
      box-sizing: border-box;
      background: #FFFFFF;
    }

    .ant-input-number {
      border: 0;
      padding: 7px 10px;
      background: #f8f8f8;
      border-radius: 8px;

      &:focus {
        box-shadow: 0 0 0 1px #F1F1F1;
        box-sizing: border-box;
        background: #FFFFFF;
      }
    }

    textarea.ant-input {
      height: 146px;
      border: 0;
      padding: 12px 20px;
      background: rgba(241, 241, 241, 0.5);
      border-radius: 8px;

      &:focus {
        box-shadow: 0 0 0 1px #F1F1F1;
        box-sizing: border-box;
        background: #FFFFFF;
      }
    }
  }

  span.ant-input-affix-wrapper {
    border: 0;
    padding: 12px 20px;
    background: rgba(241, 241, 241, 0.5);
    border-radius: 8px;

    input.ant-input {
      background: none;
    }
  }

  button.ant-btn-primary {
    height: 45px;
    background: #EF8A37;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;

    &:hover {
      background: #f69d51;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    &[disabled] {
      background: #CFD6DC;
      color: white;
    }
  }

  button.ant-btn-dashed {
    height: 45px;
    border-radius: 5px;
    text-transform: uppercase;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  button.ant-btn-field {
    height: 40px;
    margin-top: 32px;
    border-radius: 5px;
    text-transform: uppercase;
    color: rgb(239 138 55);
    border-color: #ef8a37;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    &[disabled] {
      color: #CFD6DC;
      border-color: #CFD6DC;
    }
  }

  button.ant-btn-link {
    height: 45px;
    border-radius: 5px;
    text-transform: uppercase;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  button.ant-btn-default {
    height: 45px;
    border-radius: 5px;
    text-transform: uppercase;
    color: rgb(239 138 55);
    border-color: #ef8a37;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }

    &[disabled] {
      color: #CFD6DC;
      border-color: #CFD6DC;
    }
  }

  div.dd-container {
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    padding-right: 40px;

    div.dd-container-main {
      width: 100%;
      height: 100%;
      padding: 40px;
      background: #ffffff;
      box-shadow: 0 16px 18px 3px rgb(80 57 52 / 5%);
      overflow: auto;

      h2 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        text-transform: uppercase;
        color: #4E4E4E;
      }

      label.ant-form-item-required {
        white-space: nowrap;
      }
    }
  }

  span.dd-table-header-title {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4E4E4E;
  }
  span.dd-table-header-subtitle {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #4E4E4E;
  }

  .ant-spin-nested-loading {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > div.ant-spin-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    z-index: 2000;
  }

  .ant-select-selector {
    height: 44px;
    background: rgba(241, 241, 241, 0.5);
    border-radius: 8px;
    border-color: #f8f8f8;

    :hover {
      border-color: #f69d51;
    }

    ::after {
      line-height: 42px;
    }
  }

  .ant-btn {
    span {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }

  .ant-tabs {
    margin-top: 8px;
  }
  .ant-tabs-tab {
    font-size: 16px;
    padding-right: 12px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #f69d51;
  }
  .ant-tabs-ink-bar {
    background: none;
  }
  .ant-tabs-nav {
    margin-bottom: 4px;
    &::before {
      border-bottom: none;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    padding-left: 12px;
    margin: 0;
    box-shadow: -18px 0px 0px -17px #CFD6DC;
  }

  .ant-picker {
    height: 44px;
    background: rgba(241, 241, 241, 0.5);
    border-radius: 8px;
    border-color: #f8f8f8;
  }

  .dd-table {
    width: 100%;
    height: calc(100% - 60px);
    .ant-table-wrapper {
      height: 100%;
      width: 100%;

      .ant-spin-nested-loading {
        height: 100%;
        width: 100%;

        .ant-spin-container {
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: column nowrap;

          .ant-table {
            flex: auto;
            overflow: hidden;

            .ant-table-container {
              height: 100%;
              width: 100%;
              display: flex;
              flex-flow: column nowrap;

              .ant-table-header {
                flex: none;
              }

              .ant-table-body {
                flex: auto;
                overflow: scroll;
              }
            }
          }

          .ant-table-pagination {
            flex: none;
          }
        }
      }
    }
  }

  .ant-pagination-item-link, .ant-pagination-item {
    min-width: 41px;
    height: 43px;
    background-color: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 6px;
    line-height: 42px;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container, .ant-pagination-jump-next .ant-pagination-item-container {
    position: relative;
    top: -40px;
  }

  tbody.ant-table-tbody {
    tr:nth-child(2n + 1) {
      background: rgba(241, 241, 241, 0.5);
    }
  }

  .ant-tabs-tab:hover {
    color: #f69d51;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #f69d51;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #f69d51;
    border-color: #f69d51;
  }
  .ant-btn-link {
    color: #f69d51;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: 0 0 0 2px rgba(255, 163, 24, 0.2);
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #fff4e6;
  }
  .ant-picker-focused {
    box-shadow: 0 0 0 2px rgba(255, 163, 24, 0.2);
  }
  .ant-picker-range .ant-picker-active-bar {
    background: #f69d51;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #f69d51;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #f69d51;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #f69d51;
    border-bottom: 1px dashed #f69d51;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-left: 1px dashed #f69d51;
  }
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed #f69d51;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #fff4e6;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #fff4e6;
  }
  .ant-pagination-item-active a {
    color: #f69d51;
  }
  .ant-pagination-item:hover a {
    color: #f69d51;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px rgba(255, 163, 24, 0.2);
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #f69d51;
  }
  .ant-radio-inner::after {
    background-color: #f69d51;
  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #f69d51;
  }
  .ant-input-number-focused {
    box-shadow: 0 0 0 2px rgba(255, 163, 24, 0.2);
  }
}
