.rowStatic {
  :global(.dd-organization-static) {
    padding: 25px;
    background: #FFFFFF;
    border: 1px solid #CFD6DC;
    box-sizing: border-box;
    border-radius: 10px;
    width: 215px;
    height: 197px;
    margin-right: 8px;
    margin-bottom: 8px;

    :global(.dd-organization-static-text) {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      color: #6D7D8B;
      margin-top: 10px;

      span {
        white-space: nowrap;
      }
    }

    :global(.dd-organization-static-static) {
      width: 100%;
      text-align: center;
      padding-top: 3px;
      font-size: 18px;
    }
  }
}
