.form {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-content: center;
  align-items: normal;
  height: 100%;

  :global(.ant-input-number) {
    width: 150px;
  }
}

.voteContainers {
  display: flex;
  flex-direction: column;
}

.voteMain {
  height: 100%;
  justify-content: flex-start;
}

.voteButton {
  padding-top: 10px;
}
