.modal {
  & > div {
    width: 1000px !important;
    height: 80%;
  }
  :global(.ant-modal-content) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  :global(.ant-modal-header) {
    padding: 20px 35px;
    display: flex;
  }
  :global(.ant-modal-body) {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 0;

    & > div {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
  }
  :global(.ant-modal-footer) {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 3px;
    border: none;
    align-items: center;
    gap: 8px;
  }
  :global(.ant-modal-title) {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: #4E4E4E;
  }

  :global(button.ant-btn-primary) {
    height: 45px;
    background: #EF8A37;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;

    &:hover {
      background: #f69d51;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  :global(button.ant-btn-default) {
    height: 45px;
    border-radius: 5px;
    text-transform: uppercase;
    color: rgb(239 138 55);
    border-color: #ef8a37;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  :global(input.ant-input) {
    border: 0;
    padding: 12px 20px;
    background: rgba(241, 241, 241, 0.5);
    border-radius: 8px;

    &:focus {
      box-shadow: 0 0 0 1px #F1F1F1;
      box-sizing: border-box;
      background: #FFFFFF;
    }
  }

  :global(form) {
    :global(.ant-form-item) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      height: 100%;
      align-content: flex-end;
      flex-wrap: wrap;

      :global(label) {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #6D7D8B;
      }
      :global(.ant-form-item-control) {
        flex-grow: 0;
      }
    }
  }

  :global(.ant-modal-close-x) {
    width: 35px;
    height: 32px;
    line-height: 40px;
  }

  :global(.dd-header-space) {
    display: flex;
    justify-content: space-between;

    :global(.ant-space-item) {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  :global(.dd-header-text) {
    width: 240px;
    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      text-transform: uppercase;
      color: #4E4E4E;
      margin-bottom: 0;
    }
  }
  :global(.dd-header-input) {
    :global(.dd-header-input-div) {
      width: 100%;
      & > div {
        width: 100%;
      }
      :global(.ant-select) {
        width: 100%;
      }
    }

    :global(input.ant-input) {
      padding: 0;
    }
  }

  :global(.dd-table) {
    height: 100% !important;
    overflow: hidden;
  }
}
