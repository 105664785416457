.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form {
  width: 350px;
  background: #FFFFFF;
  box-shadow: 0px 16px 18px 3px rgba(80, 57, 52, 0.05);
  padding: 40px;

  & > span {
    display: block;
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
  }

  :global(form) {
    :global(div.ant-form-item:last-child) {
      margin-bottom: 0;
    }
  }

}
