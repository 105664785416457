.containers {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

.header {
  display: flex;
  span {
    background: #EF8A37;
    border-radius: 5px 5px 0px 0px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    display: block;
    padding: 10px 20px;
    text-transform: uppercase;
  }
}

.main {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(239, 138, 55, 0.2);
  box-sizing: border-box;
  border-radius: 0px 5px 5px 5px;
  padding: 0 10px;

  :global(.ant-tag) {
    float: left;
    display: block;
    height: fit-content;
    padding: 12px 20px;
    background: #F1F1F1;
    border-radius: 5px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border: none;
  }
}
