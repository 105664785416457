.container {
  min-width: 500px;
  height: 100%;
  box-sizing: border-box;
  padding-left: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
}

@media (max-width: 1000px){
  .container {
    min-width: 100px;
  }
}

.containerMenu {
  width: 100%;
  height: 100%;
  background: #ffffff9e;
  box-shadow: 0 16px 18px 3px rgba(80, 57, 52, 0.05);
  padding: 53px 40px;
  overflow: auto;

  a {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #000000;
    padding-bottom: 12px;
    margin-bottom: 44px;

    & > img {
      margin-right: 17px;
    }
  }

  :global(a.active){
    box-shadow: 0 1px 0 0 #EF8A37;
  }
}

@media (max-width: 1000px){
  .containerMenu {
    padding: 53px 25px;
    a {
      & > img {
        width: 40px;
      }
    }
  }
}

.disable {
  position: relative;
  opacity: 0.3;

  a {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: black;
  }
}
