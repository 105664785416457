@import '../../styles/config.scss';

.header {
  @include containing-flex-row();
  justify-content: space-between;
  height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 4px 18px -5px rgba(80, 57, 52, 0.1);
  z-index: 1;
}

.logo {
  @include containing-flex-row();
  align-items: center;
  padding-left: 80px;

  img {
    width: 60px;
  }

  span {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 14px;
    font-size: 18px;
    font-weight: 700;
    color: #EF8A37;
  }
}
