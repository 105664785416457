@import '../../styles/config.scss';

.layout {
  @include containing-flex-column();
  height: 100%;
}

.container {
  @include containing-flex-row();
  overflow: hidden;
  display: flex;
  flex: auto;
  flex-grow: 0;
  flex-direction: row !important;
  height: 100%;
  justify-content: space-evenly;
  align-content: stretch;
  align-items: center;

  background: url("../../../public/images/circle-left.svg") bottom left/300px 300px no-repeat,
  url("../../../public/images/circle-rigth.svg") top right/300px 300px no-repeat;
}

.children {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
}
