.container {
  display: flex;
  padding-right: 40px;
  align-items: center;
  img {
    width: 30px;
    position: relative;
    right: -2px;
    top: -1px;
  }
  :global(.ant-btn) {
    margin-left: 10px;
  }
}
.containerUser {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  :global(span.fio) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  :global(span.role) {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #6D7D8B;
  }
}
