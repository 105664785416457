.containers {
  display: flex;
  flex-direction: column;
}

.header {
  padding-bottom: 16px;
}

.main {
  height: 100%;
  overflow: hidden;
}

.footer {
  position: absolute;
  bottom: 75px;
}

.imports {
  font-size: 12px;
  margin-bottom: 0.5em;
}

.download {
  padding-left: 0.5em;
}