.button {
  span {
    font-weight: 400 !important;
    font-size: 15px !important;
    text-decoration-line: underline;
    color: #6D7D8B;
    text-transform: capitalize !important;

    &:hover {
      color: #EF8A37;
    }
  }
}

.containers {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
