.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;

  & > div {
    width: 90% !important;
  }

  :global(.ant-modal) {
    max-width: none;
    padding-bottom: 0;
    top: 0;
  }

  :global(.ant-modal-content) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  :global(.ant-modal-header) {
    padding: 20px 35px;
    display: flex;
  }

  :global(.ant-modal-body) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  :global(.ant-modal-footer) {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: 12px;
    left: 9px;
    border: none;
  }

  :global(.ant-modal-title) {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: #4E4E4E;
  }

  :global(ul.ant-pagination) {
    margin-bottom: 0;
  }
}
