.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .main {
    height: 100%;
    overflow: hidden;

    :global(.ant-tabs) {
      height: 100%;

      :global(.ant-tabs-nav) {
        margin-bottom: -0.5px !important;

        :global(.ant-tabs-tab) {
          background: white;
          border-radius: 5px 5px 0px 0px;
          display: block;
          box-shadow: none !important;
          border: 1px solid rgba(239, 138, 55, 0.2);

          & > div {
            color: #EF8A37 !important;
          }
        }

        :global(.ant-tabs-tab-active) {
          background: #EF8A37;
          border-radius: 5px 5px 0px 0px;
          display: block;
          & > div {
            color: white !important;
          }
        }

        :global(.ant-tabs-nav-add) {
          background: none;
          border: none;
        }
      }

      :global(.ant-tabs-content-holder) {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 16px;

        :global(.ant-tabs-content) {
          height: 100%;

          :global(.dd-tab) {
            border: 1px solid rgba(239, 138, 55, 0.2);
            border-radius: 0px 5px 5px 5px;
            height: 100%;

            :global(.dd-table) {
              height: 100% !important;

              :global(ul.ant-pagination) {
                //position: fixed;
                bottom: 150px;
                right: 80px;
              }
            }
          }
        }
      }
    }
  }

  .tag {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 10px;

    :global(.ant-tag) {
      float: left;
      display: block;
      height: fit-content;
      padding: 12px 20px;
      background: #F1F1F1;
      border-radius: 5px;
      margin-top: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      border: none;
    }
  }
}
