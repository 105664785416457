.modal {
  & > div {
    width: 800px !important;
    height: 80%;
  }
  :global(.ant-modal-content) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  :global(.ant-modal-header) {
    padding: 20px 35px;
    display: flex;
  }
  :global(.ant-modal-body) {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  :global(.ant-modal-footer) {
    display: flex;
    justify-content: center;
  }
  :global(.ant-modal-title) {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
    color: #4E4E4E;
  }

  :global(button.ant-btn-primary) {
    height: 45px;
    background: #EF8A37;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;

    &:hover {
      background: #f69d51;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  :global(button.ant-btn-default) {
    height: 45px;
    border-radius: 5px;
    text-transform: uppercase;
    color: rgb(239 138 55);
    border-color: #ef8a37;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  :global(input.ant-input) {
    border: 0;
    padding: 12px 20px;
    background: rgba(241, 241, 241, 0.5);
    border-radius: 8px;

    &:focus {
      box-shadow: 0 0 0 1px #F1F1F1;
      box-sizing: border-box;
      background: #FFFFFF;
    }
  }

  :global(form) {
    :global(.ant-form-item) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: column;
      height: 100%;
      align-content: flex-end;
      flex-wrap: wrap;

      :global(label) {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #6D7D8B;
      }
      :global(.ant-form-item-control) {
        flex-grow: 0;
      }
    }
  }
}

.tag {
  padding-top: 15px;

  :global(.ant-tag) {
    padding: 12px 20px;
    background: #F1F1F1;
    border-radius: 5px;
    position: relative;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #4E4E4E;

    margin-bottom: 9px;

    border: none;

    :global(.ant-tag-close-icon) {
      position: absolute;
      background: #FFFFFF;
      border: 1px solid #CFD6DC;
      border-radius: 50%;
      padding: 3px;
      right: -6px;
      top: -6px;

      &:hover {
        border: 1px solid #6D7D8B;
      }
    }
  }
}
